export let employmentHistory: ({ city: string; jobTitle: string; employer: string; dates: string; points: string[] })[];
employmentHistory = [
    {
        jobTitle: 'Lead Software Developer',
        employer: 'Luxoft Serbia',
        city: 'Belgrade',
        dates: 'June 2022 - Present',
        points: [
            'Migrating portfolio of critical business applications from a legacy proprietary application hosting platform to open source and cloud-ready architecture and infrastructure',
            'Development of an internal UI library to unify application design and create highly efficient universal components',
            'Conducting technical interviews for front-end senior and team lead positions, preparing tasks and' +
            ' questions, assessing a candidate, preparing feedback'
        ]
    },
    {
        jobTitle: 'Team Lead',
        employer: 'Luxoft Russia',
        city: 'Moscow',
        dates: 'May 2019 - July 2022',
        points: [
            'Firefighting, conducting SCRUM ceremonies, risk assessment, process optimization, people management' +
            ', code review',
            'Front-end development system, the purpose of which is to increase the efficiency of the bank in terms of ' +
            'management and development of the credit risk monitoring with a large number of tables with a tree type of ' +
            'data, graphs and forms with a nested structure',
            'Backend development of microservices for storing and modifying order information, processing data from ' +
            'Apache Kafka and RabbitMQ queues and sending changes to the queue',
            'Conducting technical interviews for front-end senior and team lead positions, preparing tasks and' +
            ' questions, assessing a candidate, preparing feedback, onboarding the candidate for a project, and' +
            ' holding one-to-one meetings'
        ]
    },
    {
        jobTitle: 'Senior Lead Developer',
        employer: 'Proscom',
        city: 'Moscow',
        dates: 'February 2017 - May 2019',
        points: [
            'Mentoring and code review',
            'Development of application interfaces for medicine, finance, solar activity analytics, online schools,' +
            ' and higher education recommendation systems using React (Redux) and Angular (NgRx)',
            'Design and development of monolithic backend applications with NestJS (NodeJS) and data processing in Postrges',
            'Integration with external services via REST and SOAP, and provision of an external GraphQL API',
            'Conducting technical interviews for front-end senior and team lead positions, preparing tasks and' +
            ' questions, assessing a candidate, preparing feedback, onboarding the candidate for a project, and holding' +
            ' one-to-one meetings'
        ]
    },
    {
        jobTitle: 'Project Manager, Software Developer',
        employer: 'Lemier',
        city: 'Moscow',
        dates: 'September 2016  - January 2017',
        points: [
            'Development of an application with Apache Cordova for museums that displays content depending on ' +
            'the position in the space of the mobile device relative to the Bluetooth Low Energy tags и Content editing ' +
            'site with the usage of AngularJS and Express Framework (Node.js)',
            'Management and participation in developing applications (with Java and Swift) for scanning barcodes with ' +
            'the ability to save, search and edit product information and site for content editing with Express ' +
            '(Node.js) back-end, AngularJS (with RequireJS and Gulp) as front-end and Socket.IO to communicate between'
        ]
    },
    {
        jobTitle: 'Team Lead',
        employer: 'Emmtech',
        city: 'Fryazino',
        dates: 'January 2016  - September 2016',
        points: [
            'Development team management and mentoring, code review, application structure development',
            'Design and development of a front-end for a social network with an online store system based on AngularJS',
            'Network architecture design and participation in the development of a distance learning system with chat ' +
            'and video broadcasting, with the ability to create a schedule, record and store a video stream with' +
            ' a backend on the NodeJS and Express platform, as well as interaction between the backend and the external' +
            ' interface using Socket.IO',
            'Development of an online auction system in AngularJS with video broadcasting and chat, where PostgreSQL' +
            ' was used for data storage, and Redis for session storage'
        ]
    },
    {
        jobTitle: 'Full-stack Developer',
        employer: 'Vkostume',
        city: 'Moscow',
        dates: 'February 2014  - January 2016',
        points: [
            'Participated in the development of an order administration system, a tool for tracking goods in a warehouse, and sending orders to delivery services',
            'Added integrations with analytics systems and advertising services',
            'Developed a new cross-filter system indicating the number of products for the selected combination in real-time'
        ]
    }
];
