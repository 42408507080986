import {jsPDF} from "jspdf";
import React, {MutableRefObject, useCallback, useState} from "react";
import html2canvas from "html2canvas";
import {CircularProgress, Fab} from "@mui/material";
import {Download} from "@mui/icons-material";

const pdf = new jsPDF('p', 'px', 'a4', true);

export function DownloadButton({contentRef}: { contentRef: MutableRefObject<HTMLElement | undefined> }) {
    const [loading, setLoading] = useState(false);
    const download = useCallback(async () => {
        if (!loading && contentRef.current) {
            setLoading(true);
            const canvas = await html2canvas(contentRef.current);
            const imgData = canvas.toDataURL('image/png');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, 'MEDIUM');
            await pdf.save('cv.pdf', {returnPromise: true});
            setLoading(false);
        }
    }, [loading, contentRef]);

    return <Fab
        size="large"
        color="secondary"
        aria-label="add"
        sx={{position: "fixed", right: 20, top: 20}}
        onClick={download}
    >
        {loading? (<CircularProgress/>) : (<Download/>)}
    </Fab>;
}
