import {Box, LinearProgress, linearProgressClasses, styled, Typography} from "@mui/material";
import React from "react";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'rgb(255 255 255 / 50%)'
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#308fe8',
    },
}));
export const Skill = ({title, value}: { title: string, value: number }) => (
    <Box mb="15px">
        <Typography variant="body1" component="h3">{title}</Typography>
        <Box mt="8px">
            <BorderLinearProgress variant="determinate" value={value}/>
        </Box>
    </Box>
)
