import React, {useRef} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Avatar, Box, Container, CssBaseline, Divider, Link, Typography,} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {LinkedIn, Mail, Place, Telegram} from "@mui/icons-material";
import {employmentHistory} from "./data";
import {DownloadButton} from "./components/DownloadButton";
import {Skill} from "./components/Skill";
import {Block, SubBlock} from "./components/Blocks";


let theme = createTheme({
    typography: {
        h3: {
            fontSize: '2.75rem',
        },
        h4: {
            fontSize: '1.8rem',
        },
        h5: {
            fontSize: '1.3rem'
        }
    }
})

function App() {
    const contentRef = useRef<HTMLElement>();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <DownloadButton contentRef={contentRef}/>
            <Container style={{maxWidth: 1500, paddingLeft: 0}}>
                <Box
                    display="flex"
                    flexDirection="row"
                    width={1500}
                    height={2114}
                    borderRadius="10px"
                    overflow='hidden'
                    ref={contentRef}
                >
                    <Box
                        width={470}
                        height="100%"
                        pl={'95px'}
                        pr={"75px"}
                        pt={"110px"}
                        style={{
                            background: "#10365c",
                            color: "#fff"
                        }}
                    >
                        <Box
                            textAlign="center"
                            display="inline-flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Avatar
                                alt="Roman Shulgin"
                                src="/android-chrome-192x192.png"
                                sx={{width: 98, height: 98}}
                            />
                            <Box mt="15px">
                                <Typography variant="h3" component="h1">
                                    Roman Shulgin
                                </Typography>
                            </Box>
                            <Box m="25px">
                                <Divider sx={{width: 35, border: "1px solid", opacity: 0.5}} light/>
                            </Box>
                        </Box>
                        <Box mb='45px'>
                            <Typography
                                variant="overline"
                                textAlign="justify"
                                fontSize="1.2rem"
                                style={{opacity: 0.5}}
                            >
                                LEAD SOFTWARE DEVELOPER
                            </Typography>
                        </Box>
                        <Block title='Details'>
                            <Box display='flex' mt='20px'>
                                <Place/> <Typography>&nbsp;Haifa, Israel</Typography>
                            </Box>
                            <Box display='flex' mt='10px'>
                                <Mail/> <Typography>&nbsp;<Link
                                href='mailto: job.roman@shulgin.pro'>job.roman@shulgin.pro</Link></Typography>
                            </Box>
                            <Box display='flex' mt='10px'>
                                <LinkedIn/> <Typography>&nbsp;<Link
                                href='https://www.linkedin.com/in/roman-shulgin-pro/'>Roman Shulgin</Link></Typography>
                            </Box>
                            <Box display='flex' mt='10px'>
                                <Telegram/> <Typography>&nbsp;<Link
                                href='https://t.me/rshulgin'>@RShulgin</Link></Typography>
                            </Box>
                        </Block>
                        <Block title='Skills'>
                            <Box mt='10px'>
                                <Skill title={'Javascript (Typescript)'} value={100}/>
                                <Skill title={'React (Redux Thunk/Saga)'} value={100}/>
                                <Skill title={'Angular'} value={80}/>
                                <Skill title={'Docker (Compose, Kubernetes)'} value={70}/>
                                <Skill title={'Database (Postgres, Redis, MongoDB, Neo4j)'} value={85}/>
                                <Skill title={'Git (Gitflow)'} value={100}/>
                                <Skill title={'NodeJS (NestJS)'} value={100}/>
                                <Skill title={'RebbitMQ, Apache Kafka'} value={60}/>
                                <Skill title={'CI/CD (Jenkins, TeamCity)'} value={80}/>
                                <Skill title={'API (GraphQL, Swagger, Apigee)'} value={98}/>
                                <Skill title={'WebSockets (Socket.IO, STOMP, GraphQL Subscription)'} value={100}/>
                                <Skill title={'Micro Frontends (Module federation), Monorepo (Lerna, Rx, Rush)'}
                                       value={70}/>
                            </Box>
                        </Block>
                        <Block title='Languages'>
                            <Box mt='10px'>
                                <Skill title={'Russian'} value={100}/>
                                <Skill title={'English'} value={70}/>
                                <Skill title={'Hebrew'} value={1}/>
                            </Box>
                        </Block>
                    </Box>
                    <Box
                        width={1030}
                        height="100%"
                        px={"75px"}
                        pt={"110px"}
                        style={{background: '#fff'}}
                    >
                        <Block title={"Profile"}>
                            <Typography>
                                Lead JavaScript/TypeScript Full-Stack web developer with extensive experience with
                                NodeJS (including Nest) and ReactJS (including Redux-Saga and Redux-Thunk), specializing
                                in unified solutions that other teams can use, UI architecture, and improved workflow.
                                Experienced TypeScript developer using agile (including Scrum) methodology for quality
                                assurance
                                in client, server, and hybrid mobile application development, with good experience in
                                application
                                architecture design and development team management. Quickly adapts to changes and
                                enjoys
                                learning new technologies that add value to the business
                            </Typography>
                        </Block>
                        <Block title={"Employment History"}>
                            {employmentHistory.map(job => (
                                <SubBlock title={`${job.jobTitle}, ${job.employer}, ${job.city}`} subTitle={job.dates}>
                                    <Typography component='ul'>
                                        {job.points.map(point => (
                                            <li>{point}</li>
                                        ))}
                                    </Typography>
                                </SubBlock>
                            ))}
                        </Block>
                        <Block title={"Education"}>
                            <SubBlock
                                title={'Moscow technical university of communications and informatics (MTUCI), Moscow'}
                                subTitle={'Bachelor, September 2011 - July 2016'}/>
                        </Block>
                        <Block title={"Courses"}>
                            <SubBlock title={'Leadership academy'} subTitle={'June 2020 - November 2020'}/>
                            <SubBlock title={'Domain Driven Design, Luxoft'} subTitle={'February 2020'}/>
                            <SubBlock title={'Respectful and Constructive Communication, Luxoft'}
                                      subTitle={'February 2019'}/>
                        </Block>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default App;
