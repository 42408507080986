import React, {PropsWithChildren} from "react";
import {Box, Typography} from "@mui/material";

export const Block = ({children, title}: PropsWithChildren<{ title: string }>) => (
    <Box mb={'35px'}>
        <Typography variant="h4" component="h2">{title}</Typography>
        <Box>
            {children}
        </Box>
    </Box>
)
export const SubBlock = ({children, title, subTitle}: PropsWithChildren<{ title: string, subTitle: string }>) => (
    <Box mb="15px">
        <Typography variant="h5" component="h3">{title}</Typography>
        <Box>
            <Typography variant="overline" lineHeight={2} fontSize='14px' style={{opacity: 0.5}}>{subTitle}</Typography>
        </Box>
        <Box>
            {children}
        </Box>
    </Box>
)
